/* You can add global styles to this file, and also import other style files */
@import './assets/styles/variables';
@import './assets/styles/global';
@import './assets/styles/icons';
@import './assets/styles/grid';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@media(max-width: 767px) {
  @import './assets/styles/mobile-mixins';
}

@import "variables";

@font-face {
  font-family: 'icomoon';
  src: url('#{$icomoon-font-path}/icomoon.eot?x2yrbi');
  src: url('#{$icomoon-font-path}/icomoon.eot?x2yrbi#iefix') format('embedded-opentype'),
  url('#{$icomoon-font-path}/icomoon.ttf?x2yrbi') format('truetype'),
  url('#{$icomoon-font-path}/icomoon.woff?x2yrbi') format('woff'),
  url('#{$icomoon-font-path}/icomoon.svg?x2yrbi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 3px;
}

.icon-enroll .path1 {
  &:before {
    content: $icon-enroll-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-enroll .path2 {
  &:before {
    content: $icon-enroll-path2;
    margin-left: -0.7861328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-enroll .path3 {
  &:before {
    content: $icon-enroll-path3;
    margin-left: -0.7861328125em;
    color: rgb(0, 65, 112);
  }
}

.icon-FAQs {
  &:before {
    content: $icon-FAQs;
    color: #004170;
  }
}

.icon-Notification_new .path1 {
  &:before {
    content: $icon-Notification_new-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Notification_new .path2 {
  &:before {
    content: $icon-Notification_new-path2;
    margin-left: -1.6953125em;
    color: rgb(255, 0, 0);
  }
}

.icon-switch_off_light .path1 {
  &:before {
    content: $icon-switch_off_light-path1;
    color: rgba(34, 31, 31, 0.26);
  }
}

.icon-switch_off_light .path2 {
  &:before {
    content: $icon-switch_off_light-path2;
    margin-left: -1.5185546875em;
    color: rgb(241, 241, 241);
  }
}

.icon-switch_on_light .path1 {
  &:before {
    content: $icon-switch_on_light-path1;
    color: rgb(0, 65, 112);
    opacity: 0.5;
  }
}

.icon-switch_on_light .path2 {
  &:before {
    content: $icon-switch_on_light-path2;
    margin-left: -1.5380859375em;
    color: rgb(0, 65, 112);
  }
}

.icon-Thunderstorm {
  &:before {
    content: $icon-Thunderstorm;
    color: #004170;
  }
}

.icon-other {
  &:before {
    content: $icon-other;
    color: #003e6b;
  }
}

.icon-info_white .path1 {
  &:before {
    content: $icon-info_white-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-info_white .path2 {
  &:before {
    content: $icon-info_white-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-info_white .path3 {
  &:before {
    content: $icon-info_white-path3;
    margin-left: -1em;
    color: none;
  }
}

.icon-info_white .path4 {
  &:before {
    content: $icon-info_white-path4;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-info_white .path5 {
  &:before {
    content: $icon-info_white-path5;
    margin-left: -1em;
    color: rgb(0, 64, 111);
  }
}

.icon-inspection-firm .path1 {
  &:before {
    content: $icon-inspection-firm-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-inspection-firm .path2 {
  &:before {
    content: $icon-inspection-firm-path2;
    margin-left: -1.1533203125em;
    color: rgb(0, 65, 112);
  }
}

.icon-inspection-firm .path3 {
  &:before {
    content: $icon-inspection-firm-path3;
    margin-left: -1.1533203125em;
    color: rgb(255, 255, 255);
  }
}

.icon-adjuster .path1 {
  &:before {
    content: $icon-adjuster-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-adjuster .path2 {
  &:before {
    content: $icon-adjuster-path2;
    margin-left: -1.1474609375em;
    color: rgb(0, 65, 112);
  }
}

.icon-adjuster .path3 {
  &:before {
    content: $icon-adjuster-path3;
    margin-left: -1.1474609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-adjuster .path4 {
  &:before {
    content: $icon-adjuster-path4;
    margin-left: -1.1474609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-adjuster .path5 {
  &:before {
    content: $icon-adjuster-path5;
    margin-left: -1.1474609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-adjuster .path6 {
  &:before {
    content: $icon-adjuster-path6;
    margin-left: -1.1474609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-emergency-services .path1 {
  &:before {
    content: $icon-emergency-services-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-emergency-services .path2 {
  &:before {
    content: $icon-emergency-services-path2;
    margin-left: -1.1611328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-emergency-services .path3 {
  &:before {
    content: $icon-emergency-services-path3;
    margin-left: -1.1611328125em;
    color: rgb(0, 65, 112);
  }
}

.icon-emergency-services .path4 {
  &:before {
    content: $icon-emergency-services-path4;
    margin-left: -1.1611328125em;
    color: rgb(0, 65, 112);
  }
}

.icon-emergency-services .path5 {
  &:before {
    content: $icon-emergency-services-path5;
    margin-left: -1.1611328125em;
    color: rgb(0, 65, 112);
  }
}

.icon-emergency-services .path6 {
  &:before {
    content: $icon-emergency-services-path6;
    margin-left: -1.1611328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-emergency-services .path7 {
  &:before {
    content: $icon-emergency-services-path7;
    margin-left: -1.1611328125em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36482 {
  &:before {
    content: $icon-Group-36482;
    color: #004170;
  }
}

.icon-Group-36492 .path1 {
  &:before {
    content: $icon-Group-36492-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36492 .path2 {
  &:before {
    content: $icon-Group-36492-path2;
    margin-left: -1.076171875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3645 {
  &:before {
    content: $icon-Group-3645;
    color: #004170;
  }
}

.icon-Group-36472 {
  &:before {
    content: $icon-Group-36472;
    color: #515151;
  }
}

.icon-Group-36462 .path1 {
  &:before {
    content: $icon-Group-36462-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36462 .path2 {
  &:before {
    content: $icon-Group-36462-path2;
    margin-left: -1.0419921875em;
    color: rgb(244, 243, 241);
  }
}

.icon-Group-36462 .path3 {
  &:before {
    content: $icon-Group-36462-path3;
    margin-left: -1.0419921875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36462 .path4 {
  &:before {
    content: $icon-Group-36462-path4;
    margin-left: -1.0419921875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3622 .path1 {
  &:before {
    content: $icon-Group-3622-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3622 .path2 {
  &:before {
    content: $icon-Group-3622-path2;
    margin-left: -0.779296875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3622 .path3 {
  &:before {
    content: $icon-Group-3622-path3;
    margin-left: -0.779296875em;
    color: rgb(228, 228, 228);
  }
}

.icon-Group-36342 .path1 {
  &:before {
    content: $icon-Group-36342-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36342 .path2 {
  &:before {
    content: $icon-Group-36342-path2;
    margin-left: -0.8466796875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36342 .path3 {
  &:before {
    content: $icon-Group-36342-path3;
    margin-left: -0.8466796875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-36342 .path4 {
  &:before {
    content: $icon-Group-36342-path4;
    margin-left: -0.8466796875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36342 .path5 {
  &:before {
    content: $icon-Group-36342-path5;
    margin-left: -0.8466796875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36352 .path1 {
  &:before {
    content: $icon-Group-36352-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36352 .path2 {
  &:before {
    content: $icon-Group-36352-path2;
    margin-left: -0.7978515625em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36352 .path3 {
  &:before {
    content: $icon-Group-36352-path3;
    margin-left: -0.7978515625em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-36352 .path4 {
  &:before {
    content: $icon-Group-36352-path4;
    margin-left: -0.7978515625em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36352 .path5 {
  &:before {
    content: $icon-Group-36352-path5;
    margin-left: -0.7978515625em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-36312 {
  &:before {
    content: $icon-Group-36312;
    color: #515151;
  }
}

.icon-Group-3619 .path1 {
  &:before {
    content: $icon-Group-3619-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3619 .path2 {
  &:before {
    content: $icon-Group-3619-path2;
    margin-left: -0.521484375em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3619 .path3 {
  &:before {
    content: $icon-Group-3619-path3;
    margin-left: -0.521484375em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3616 .path1 {
  &:before {
    content: $icon-Group-3616-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3616 .path2 {
  &:before {
    content: $icon-Group-3616-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3620 .path1 {
  &:before {
    content: $icon-Group-3620-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3620 .path2 {
  &:before {
    content: $icon-Group-3620-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3620 .path3 {
  &:before {
    content: $icon-Group-3620-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3621 .path1 {
  &:before {
    content: $icon-Group-3621-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3621 .path2 {
  &:before {
    content: $icon-Group-3621-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3621 .path3 {
  &:before {
    content: $icon-Group-3621-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3623 {
  &:before {
    content: $icon-Group-3623;
    color: #004170;
  }
}

.icon-Group-36242 {
  &:before {
    content: $icon-Group-36242;
    color: #004170;
  }
}

.icon-noun_915590_cc {
  &:before {
    content: $icon-noun_915590_cc;
  }
}

.icon-noun_952915_cc {
  &:before {
    content: $icon-noun_952915_cc;
  }
}

.icon-noun_952921_cc {
  &:before {
    content: $icon-noun_952921_cc;
  }
}

.icon-Video .path1 {
  &:before {
    content: $icon-Video-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Video .path2 {
  &:before {
    content: $icon-Video-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Information_inactive-state {
  &:before {
    content: $icon-Information_inactive-state;
    color: #515151;
  }
}

.icon-Policy-Documents_inactive-state .path1 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path1;
    color: rgb(81, 81, 81);
  }
}

.icon-Policy-Documents_inactive-state .path2 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path2;
    margin-left: -0.8154296875em;
    color: rgb(81, 81, 81);
  }
}

.icon-Policy-Documents_inactive-state .path3 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path3;
    margin-left: -0.8154296875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Policy-Documents_inactive-state .path4 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path4;
    margin-left: -0.8154296875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Policy-Documents_inactive-state .path5 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path5;
    margin-left: -0.8154296875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Policy-Documents_inactive-state .path6 {
  &:before {
    content: $icon-Policy-Documents_inactive-state-path6;
    margin-left: -0.8154296875em;
    color: rgb(255, 255, 255);
  }
}

.icon-accordion-open {
  &:before {
    content: $icon-accordion-open;
    color: #004170;
  }
}

.icon-accordion_closed {
  &:before {
    content: $icon-accordion_closed;
    color: #004170;
  }
}

.icon-Agent {
  &:before {
    content: $icon-Agent;
    color: #004170;
  }
}

.icon-Back {
  &:before {
    content: $icon-Back;
    color: #004170;
  }
}

.icon-Billing-n-Payments {
  &:before {
    content: $icon-Billing-n-Payments;
    color: #004170;
  }
}

.icon-Billing-n-Paymetn_gray {
  &:before {
    content: $icon-Billing-n-Paymetn_gray;
    color: #515151;
  }
}

.icon-Change-Edit {
  &:before {
    content: $icon-Change-Edit;
    color: #004170;
  }
}

.icon-Chat-with-us {
  &:before {
    content: $icon-Chat-with-us;
    color: #004170;
  }
}

.icon-Check-box_unchecked .path1 {
  &:before {
    content: $icon-Check-box_unchecked-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Check-box_unchecked .path2 {
  &:before {
    content: $icon-Check-box_unchecked-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-check_on_light {
  &:before {
    content: $icon-check_on_light;
    color: #004170;
  }
}

.icon-Claims {
  &:before {
    content: $icon-Claims;
    color: #004170;
  }
}

.icon-Close .path1 {
  &:before {
    content: $icon-Close-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Close .path2 {
  &:before {
    content: $icon-Close-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Close .path3 {
  &:before {
    content: $icon-Close-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Doors {
  &:before {
    content: $icon-Doors;
    color: #004170;
  }
}

.icon-Exit-Logout {
  &:before {
    content: $icon-Exit-Logout;
    color: #004170;
  }
}

.icon-Fax {
  &:before {
    content: $icon-Fax;
    color: #0c4066;
  }
}

.icon-Group-3626 .path1 {
  &:before {
    content: $icon-Group-3626-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3626 .path2 {
  &:before {
    content: $icon-Group-3626-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3626 .path3 {
  &:before {
    content: $icon-Group-3626-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3627 {
  &:before {
    content: $icon-Group-3627;
    color: #004170;
  }
}

.icon-Group-3628 .path1 {
  &:before {
    content: $icon-Group-3628-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3628 .path2 {
  &:before {
    content: $icon-Group-3628-path2;
    margin-left: -1.3857421875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3628 .path3 {
  &:before {
    content: $icon-Group-3628-path3;
    margin-left: -1.3857421875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3628 .path4 {
  &:before {
    content: $icon-Group-3628-path4;
    margin-left: -1.3857421875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3629 .path1 {
  &:before {
    content: $icon-Group-3629-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3629 .path2 {
  &:before {
    content: $icon-Group-3629-path2;
    margin-left: -1.3857421875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3630 .path1 {
  &:before {
    content: $icon-Group-3630-path1;
    color: rgb(0, 65, 112);
    opacity: 0.5;
  }
}

.icon-Group-3630 .path2 {
  &:before {
    content: $icon-Group-3630-path2;
    margin-left: -0.6728515625em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3630 .path3 {
  &:before {
    content: $icon-Group-3630-path3;
    margin-left: -0.6728515625em;
    color: rgba(34, 31, 31, 0.26);
  }
}

.icon-Group-3630 .path4 {
  &:before {
    content: $icon-Group-3630-path4;
    margin-left: -0.6728515625em;
    color: rgb(241, 241, 241);
  }
}

.icon-Group-3631 {
  &:before {
    content: $icon-Group-3631;
    color: #004170;
    margin-right: 4px;
  }
}

.icon-Group-3632 .path1 {
  &:before {
    content: $icon-Group-3632-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3632 .path2 {
  &:before {
    content: $icon-Group-3632-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3632 .path3 {
  &:before {
    content: $icon-Group-3632-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3634 {
  &:before {
    content: $icon-Group-3634;
    color: #0c4066;
  }
}

.icon-Group-3635 .path1 {
  &:before {
    content: $icon-Group-3635-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3635 .path2 {
  &:before {
    content: $icon-Group-3635-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3635 .path3 {
  &:before {
    content: $icon-Group-3635-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3636 .path1 {
  &:before {
    content: $icon-Group-3636-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3636 .path2 {
  &:before {
    content: $icon-Group-3636-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3637 .path1 {
  &:before {
    content: $icon-Group-3637-path1;
    color: rgb(81, 81, 81);
  }
}

.icon-Group-3637 .path2 {
  &:before {
    content: $icon-Group-3637-path2;
    margin-left: -0.8134765625em;
    color: rgb(81, 81, 81);
  }
}

.icon-Group-3637 .path3 {
  &:before {
    content: $icon-Group-3637-path3;
    margin-left: -0.8134765625em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3638 .path1 {
  &:before {
    content: $icon-Group-3638-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3638 .path2 {
  &:before {
    content: $icon-Group-3638-path2;
    margin-left: -0.521484375em;
    color: none;
  }
}

.icon-Group-3638 .path3 {
  &:before {
    content: $icon-Group-3638-path3;
    margin-left: -0.521484375em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3638 .path4 {
  &:before {
    content: $icon-Group-3638-path4;
    margin-left: -0.521484375em;
    color: none;
  }
}

.icon-Group-3638 .path5 {
  &:before {
    content: $icon-Group-3638-path5;
    margin-left: -0.521484375em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3639 .path1 {
  &:before {
    content: $icon-Group-3639-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3639 .path2 {
  &:before {
    content: $icon-Group-3639-path2;
    margin-left: -1.2861328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3639 .path3 {
  &:before {
    content: $icon-Group-3639-path3;
    margin-left: -1.2861328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3639 .path4 {
  &:before {
    content: $icon-Group-3639-path4;
    margin-left: -1.2861328125em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3641 {
  &:before {
    content: $icon-Group-3641;
    color: #fff;
  }
}

.icon-Group-3646 {
  &:before {
    content: $icon-Group-3646;
    color: #f00;
  }
}

.icon-Group-3648 {
  &:before {
    content: $icon-Group-3648;
  }
}

.icon-Group-3649 .path1 {
  &:before {
    content: $icon-Group-3649-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3649 .path2 {
  &:before {
    content: $icon-Group-3649-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3649 .path3 {
  &:before {
    content: $icon-Group-3649-path3;
    margin-left: -1em;
    color: none;
  }
}

.icon-Group-3650 .path1 {
  &:before {
    content: $icon-Group-3650-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3650 .path2 {
  &:before {
    content: $icon-Group-3650-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3650 .path3 {
  &:before {
    content: $icon-Group-3650-path3;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3651 .path1 {
  &:before {
    content: $icon-Group-3651-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3651 .path2 {
  &:before {
    content: $icon-Group-3651-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3651 .path3 {
  &:before {
    content: $icon-Group-3651-path3;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3652 .path1 {
  &:before {
    content: $icon-Group-3652-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3652 .path2 {
  &:before {
    content: $icon-Group-3652-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3652 .path3 {
  &:before {
    content: $icon-Group-3652-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3655 .path1 {
  &:before {
    content: $icon-Group-3655-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3655 .path2 {
  &:before {
    content: $icon-Group-3655-path2;
    margin-left: -1.5849609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3655 .path3 {
  &:before {
    content: $icon-Group-3655-path3;
    margin-left: -1.5849609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3655 .path4 {
  &:before {
    content: $icon-Group-3655-path4;
    margin-left: -1.5849609375em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3656 {
  &:before {
    content: $icon-Group-3656;
    color: #004170;
  }
}

.icon-Group-3657 .path1 {
  &:before {
    content: $icon-Group-3657-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3657 .path2 {
  &:before {
    content: $icon-Group-3657-path2;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3657 .path3 {
  &:before {
    content: $icon-Group-3657-path3;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3657 .path4 {
  &:before {
    content: $icon-Group-3657-path4;
    margin-left: -2em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3658 {
  &:before {
    content: $icon-Group-3658;
    color: #004170;
  }
}

.icon-Group-3659 {
  &:before {
    content: $icon-Group-3659;
    color: #004170;
  }
}

.icon-Group-3660 {
  &:before {
    content: $icon-Group-3660;
    color: #004170;
  }
}

.icon-Group-3661 {
  &:before {
    content: $icon-Group-3661;
    color: #004170;
  }
}

.icon-Group-3662 {
  &:before {
    content: $icon-Group-3662;
    color: #004170;
  }
}

.icon-Group-3663 {
  &:before {
    content: $icon-Group-3663;
    color: #004170;
  }
}

.icon-Group-3664 {
  &:before {
    content: $icon-Group-3664;
    color: #004170;
  }
}

.icon-Group-3665 {
  &:before {
    content: $icon-Group-3665;
    color: #004170;
  }
}

.icon-Group-3666 {
  &:before {
    content: $icon-Group-3666;
    color: #004170;
  }
}

.icon-Group-3667 {
  &:before {
    content: $icon-Group-3667;
    color: #004170;
  }
}

.icon-Group-3668 {
  &:before {
    content: $icon-Group-3668;
    color: #004170;
  }
}

.icon-Group-3669 .path1 {
  &:before {
    content: $icon-Group-3669-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3669 .path2 {
  &:before {
    content: $icon-Group-3669-path2;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3669 .path3 {
  &:before {
    content: $icon-Group-3669-path3;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3669 .path4 {
  &:before {
    content: $icon-Group-3669-path4;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3669 .path5 {
  &:before {
    content: $icon-Group-3669-path5;
    margin-left: -1em;
    color: none;
  }
}

.icon-Group-3672 .path1 {
  &:before {
    content: $icon-Group-3672-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3672 .path2 {
  &:before {
    content: $icon-Group-3672-path2;
    margin-left: -1.169921875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3672 .path3 {
  &:before {
    content: $icon-Group-3672-path3;
    margin-left: -1.169921875em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3672 .path4 {
  &:before {
    content: $icon-Group-3672-path4;
    margin-left: -1.169921875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3672 .path5 {
  &:before {
    content: $icon-Group-3672-path5;
    margin-left: -1.169921875em;
    color: none;
  }
}

.icon-Group-3672 .path6 {
  &:before {
    content: $icon-Group-3672-path6;
    margin-left: -1.169921875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3672 .path7 {
  &:before {
    content: $icon-Group-3672-path7;
    margin-left: -1.169921875em;
    color: none;
  }
}

.icon-Group-3672 .path8 {
  &:before {
    content: $icon-Group-3672-path8;
    margin-left: -1.169921875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3672 .path9 {
  &:before {
    content: $icon-Group-3672-path9;
    margin-left: -1.169921875em;
    color: none;
  }
}

.icon-Group-3673 .path1 {
  &:before {
    content: $icon-Group-3673-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3673 .path2 {
  &:before {
    content: $icon-Group-3673-path2;
    margin-left: -1em;
    color: none;
  }
}

.icon-Group-3673 .path3 {
  &:before {
    content: $icon-Group-3673-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3674 .path1 {
  &:before {
    content: $icon-Group-3674-path1;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3674 .path2 {
  &:before {
    content: $icon-Group-3674-path2;
    margin-left: -1em;
    color: none;
  }
}

.icon-Group-3674 .path3 {
  &:before {
    content: $icon-Group-3674-path3;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3674 .path4 {
  &:before {
    content: $icon-Group-3674-path4;
    margin-left: -1em;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3675 {
  &:before {
    content: $icon-Group-3675;
    color: #003e6b;
  }
}

.icon-Group-3676 .path1 {
  &:before {
    content: $icon-Group-3676-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3676 .path2 {
  &:before {
    content: $icon-Group-3676-path2;
    margin-left: -1.076171875em;
    color: rgb(255, 255, 255);
  }
}

.icon-Group-3681 .path1 {
  &:before {
    content: $icon-Group-3681-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Group-3681 .path2 {
  &:before {
    content: $icon-Group-3681-path2;
    margin-left: -1.6953125em;
    color: rgb(255, 0, 0);
  }
}

.icon-Group-3681 .path3 {
  &:before {
    content: $icon-Group-3681-path3;
    margin-left: -1.6953125em;
    color: rgb(255, 255, 255);
  }
}

.icon-History {
  &:before {
    content: $icon-History;
    color: #004170;
  }
}

.icon-Image {
  &:before {
    content: $icon-Image;
    color: #004170;
  }
}

.icon-new {
  &:before {
    content: $icon-new;
    color: #f00;
  }
}

.icon-Notifications-Alerts {
  &:before {
    content: $icon-Notifications-Alerts;
    color: #004170;
  }
}

.icon-noun_-1 {
  &:before {
    content: $icon-noun_-1;
    color: #004170;
  }
}

.icon-noun_61554 {
  &:before {
    content: $icon-noun_61554;
    color: #004170;
  }
}

.icon-noun_76181_cc {
  &:before {
    content: $icon-noun_76181_cc;
    color: #004170;
  }
}

.icon-noun_173601_cc {
  &:before {
    content: $icon-noun_173601_cc;
    color: #004170;
  }
}

.icon-noun_216058_cc {
  &:before {
    content: $icon-noun_216058_cc;
    color: #004170;
  }
}

.icon-Password-Secure {
  &:before {
    content: $icon-Password-Secure;
    color: #004170;
  }
}

.icon-Path-1361 {
  &:before {
    content: $icon-Path-1361;
    color: #fff;
  }
}

.icon-Path-1363 {
  &:before {
    content: $icon-Path-1363;
    color: #ff5700;
  }
}

.icon-Phone-contact .path1 {
  &:before {
    content: $icon-Phone-contact-path1;
    color: rgb(0, 65, 112);
  }
}

.icon-Phone-contact .path2 {
  &:before {
    content: $icon-Phone-contact-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

.icon-Preferences {
  &:before {
    content: $icon-Preferences;
    color: #004170;
  }
}

.icon-Profile-Account {
  &:before {
    content: $icon-Profile-Account;
    color: #004170;
  }
}

.icon-Quick {
  &:before {
    content: $icon-Quick;
    color: #004170;
  }
}

.icon-radio_on-1 {
  &:before {
    content: $icon-radio_on-1;
    color: #004170;
  }
}

.icon-radio_on-2 {
  &:before {
    content: $icon-radio_on-2;
    color: #888;
  }
}

.icon-radio_on {
  &:before {
    content: $icon-radio_on;
    color: #004170;
  }
}

.icon-Receipt {
  &:before {
    content: $icon-Receipt;
    color: #004170;
  }
}

.icon-roof {
  &:before {
    content: $icon-roof;
    color: #004170;
  }
}

.icon-Wall {
  &:before {
    content: $icon-Wall;
    color: #004170;
  }
}

.icon-powerout {
  position: relative;
  top: 3px;

  &:before {
    content: url("../images/icons/claims/PowerOut.svg");
  }
}

.icon-wateroff {
  &:before {
    position: relative;
    top: 3px;
    content: url("../images/icons/claims/WaterOff.svg");
    color: #004170;
  }
}

.icon-ExtremeDamage {
  position: relative;
  top: 3px;

  &:before {
    content: url("../images/icons/claims/ExtremeDamage.svg");
    color: #004170;
  }
}

.icon-warning {
  position: relative;
  top: 3px;

  &:before {
    content: url("../images/icons/claims/HomeNotAccessible.svg");
    color: #004170;
  }
}

.label-position {
  position: relative;
  top: -3px;
}

.fs1 {
  font-size: 32px;
  line-height: 0;
  vertical-align: middle;
}

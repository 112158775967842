@import "./variables";

@font-face {
  font-family: "Roboto-Light";
  src: url("../fonts/Roboto-Light.ttf") format("truetype"),
  url("../fonts/Roboto-Light.woff") format("woff"),
  url("../fonts/Roboto-Light.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype"),
  url("../fonts/Roboto-Medium.woff") format("woff"),
  url("../fonts/Roboto-Medium.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype"),
  url("../fonts/Roboto-Bold.woff") format("woff"),
  url("../fonts/Roboto-Bold.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}

body {
  background-color: $white;
  font-family: "Roboto-Regular", sans-serif !important;

  &.modal-open {
    overflow-y: auto !important;
  }
}

html {
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:visited {
    text-decoration: none;
  }
}

::-ms-reveal {
  display: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.cursor-pointer {
  cursor: pointer;
}

:-ms-input-placeholder {
  color: #777;
}

.primary-textcolor {
  color: $dark-blue;
}

.secondary-textcolor {
  color: $secondary-text;
}

input,
select {
  outline: none;

  &.form-control {
    box-shadow: none;
    border: 1px solid $input-color;
    font-family: "Roboto-Regular", sans-serif !important;
    border-radius: 5px;
    padding: 8px 15px;

    &:focus {
      box-shadow: none;
      border-color: $light-blue;
    }
  }
}

.btn {
  border-radius: 5px;
  border: 0;
  font-size: 1.25rem;
  padding: 0.625rem;
  font-family: "Roboto-Regular", sans-serif;

  &:focus {
    box-shadow: none;
  }

  &.btn-primary {
    color: $white;
    background-color: $dark-blue !important;

    &:hover,
    &:active,
    &:focus {
      background-color: $dark-blue;
      box-shadow: none;
    }
  }

  &.thig-btn-default {
    background-color: #eef2fe;
    border-color: #eef2fe;
    color: #004170;
    padding: 8px 9px;
    font-size: 12px !important;
    font-weight: bold;

    &:hover,
    &:active,
    &:focus {
      background-color: $dark-blue;
      box-shadow: none;
      color: #ffffff;
    }
  }

  &.btn-link {
    background-color: transparent;
    text-decoration: none !important;
    color: $dark-blue;

    &.inverse {
      color: $white;
    }

    &.blue {
      color: #00a9e0;
      font-weight: 100;
    }
  }
}

::-ms-clear {
  display: none; /*IE -ms-clear- pseudo element hide*/
}

/*Toggle switch global css*/

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 20px;

  input {
    display: none;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #d8d7d7;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  bottom: -2px;
  background-color: #f1f1f1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  border: solid 0.5px rgba(0, 0, 0, 0.44);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
}

input:checked + .slider {
  background-color: #6196be;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: $dark-blue;
  box-shadow: none;
}

/*Icons*/

.material-icons {
  font-family: "Material Icons", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.icon-addon {
  background-size: 25px 25px !important;
  background-position: 11px center !important;
  left: 10px;
}

.icon-addon-right {
  background-size: 25px 25px !important;
  background-position: 95% center !important;
}

.icon-user {
  background: url("../images/icons/user.svg") no-repeat;
}

.icon-logout {
  background: url("../images/icons/logout.svg") no-repeat;
}

.icon-secure {
  background: url("../images/icons/secure.svg") no-repeat;
  background-size: 21px;
}

.icon-sms {
  background: url("../images/icons/sms.svg") no-repeat;
  background-size: 35px;
}

.icon-complete {
  background: url("../images/icons/reg-complete.svg") 25px/25px no-repeat;
  background-position: 0 15%;
}

.icon-password {
  background: url("../images/icons/password.svg") no-repeat;
}

.icon-ellipse {
  background: url("../images/icons/ellipse.svg") no-repeat;
}

.icon-email {
  background: url("../images/icons/email.svg") no-repeat;
}

.icon-quickaction {
  background: url("../images/icons/quick-action.svg") no-repeat;
  background-size: 25px;
}

.icon-helpinfo {
  background: url("../images/icons/helpinfo.svg") no-repeat;
}

.icon-invoice {
  background: url("../images/icons/invoice.svg") no-repeat;
}

.icon-policy {
  background: url("../images/icons/policy.svg") no-repeat;
}

.icon-phone {
  background: url("../images/icons/phone.svg") no-repeat;
}

.icon-username {
  background: url("../images/icons/username.svg") no-repeat;
}

.icon-payment {
  background: url("../images/icons/due-payment.svg") no-repeat;
}

.icon-agent {
  background: url("../images/icons/agent.svg") no-repeat;
}

.icon-newuser {
  background: url("../images/icons/new-user.svg") no-repeat;
}

.icon-report-claim {
  background: url("../images/icons/claims-home.svg") no-repeat;
}

.icon-help {
  background-position: right !important;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 4%;
  top: 30%;
}

.icon-prefs {
  background: url("../images/icons/profile-notifs.svg") 5px/15px no-repeat;
}

.icon-edocs {
  background: url("../images/icons/profile-edocs.svg") 15px/15px no-repeat;
}

.icon-showpwd {
  background: url("../images/icons/show-password.svg") 15px/15px no-repeat;
}

.icon-hidepwd {
  background: url("../images/icons/hide-password.svg") 15px/15px no-repeat;
}

.icon-location {
  background: url("../images/icons/map-pin.svg") no-repeat;
}

.bd-navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

/*Overlays custom styling*/

.modal {
  top: 30%;

  .modal-lg-custom {
    max-width: 650px;
  }

  .modal-content {
    border: none;
  }
}

/*Error class used in Input Validation*/

.has-error {
  border-color: $red !important;
}

/*T&C text*/

input[type="checkbox"] {
  height: 20px !important;
  width: 20px !important;
  background: #d8d7d7;
  -webkit-appearance: none;
  padding-left: 15px !important;
  font-size: 0.875rem;

  &:checked + i {
    background: $dark-blue;
  }
}

input[type="radio"] {
  background: url("../images/icons/radio_on-1.svg") no-repeat scroll 0 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 20px;
  width: 20px;

  &:checked {
    background: url("../images/icons/radio_on.svg") no-repeat scroll 0 0 !important;
    width: 24px;
    height: 24px;
  }
}

.icon-close {
  background: url("../images/icons/Close.svg") 25px/25px no-repeat;
  background-position: center;
}

// Datepicker -theme color
.theme-dark-blue .bs-datepicker-head {
  background-color: $dark-blue !important;
}

/* claims global css */

.popover-arrow {
  display: none !important;
}

.p-checkbox-box {
  border-color: #555 !important;
}

.p-checkbox-label {
  margin-bottom: 0 !important;
}

.p-checkbox-box.p-state-active,
.p-radiobutton-box.p-state-active {
  background: $dark-blue;
  border-color: $dark-blue;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  font-weight: 600 !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: #004170 !important;
  border-color: #004170 !important;
}

.theme-blue {
  .bs-datepicker-container {
    padding: 5px;
  }

  .bs-datepicker-head {
    background-color: $dark-blue;
  }

  .week {
    color: $dark-blue;
  }

  .bs-datepicker-body table td span.selected,
  .theme-blue .bs-datepicker-body table td.selected span,
  .theme-blue .bs-datepicker-body table td span[class*="select-"]:after,
  .theme-blue .bs-datepicker-body table td[class*="select-"] span:after {
    background-color: $dark-blue;
  }
}

.payment-plans {
  .swiper-container {
    .swiper-button-next,
    .swiper-button-prev {
      height: 20px;
      top: 60%;
    }

    .swiper-wrapper {
      .swiper-slide {
        height: auto !important;

        .plan-item {
          min-height: 100%;
          background-color: $white;
          width: 100%;
          border: 1px solid $grey;
          border-radius: 10px;
          margin: auto;

          .plan-header {
            border-radius: 10px 10px 0 0;
            background-color: $dark-blue;
            color: $white;
            padding: 10px;

            h4 {
              font-size: 16px;
              margin: 5px 0 0 0;
            }
          }

          .plan-body {
            padding: 10px;

            .plan-total {
              margin: 10px 0;
            }
          }
        }

        &.swiper-slide-active {
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.16);

          .plan-item {
            width: 100%;
            border: 1px solid $dark-blue;
          }
        }
      }
    }
  }
}

.renewalAlert {
  .customClass {
    max-width: 100%;
    width: 450px;
    min-width: 450px;
    max-height: 450px;
    overflow-y: scroll;
    left: -255px !important;
    box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: none;
    transform: none !important;
    top: 25px !important;
    z-index: 999;
    @media (max-width: 992px) {
      width: 400px;
      min-width: 400px;
    }
    @media (max-width: 767px) {
      width: 350px;
      min-width: 350px;
      left: -217px !important;
      box-shadow: 0 0 10px rgba(184, 184, 184, 0.7);
      border-radius: 0;
      padding: 0;
    }
    @media (max-width: 374px) {
      width: 320px;
      min-width: 320px;
      left: -167px !important;
    }
  }
}

.profile-alerts-popup {
  .customClass {
    max-width: 100%;
    width: 450px;
    min-width: 320px;
    max-height: 450px;
    overflow-y: scroll;
    box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    border: none;
    top: 25px;
    left: -140px !important;
    z-index: 999;
    @media (max-width: 768px) {
      width: 500px;
      max-height: 285px;
      left: 0 !important;
      box-shadow: 0 0 10px rgba(184, 184, 184, 0.7);
      border-radius: 0;
      padding: 0;
      top: 236px
    }
    @media (max-width: 530px) {
      width: 400px;
    }
    @media (max-width: 374px) {
      width: 320px;
    }
  }
}

.productrenewalAlert {
  .policy-details-notifications {
    max-width: 650px;
    width: 450px;
    z-index: 999;
    max-height: 450px;
    overflow-y: scroll;
    left: auto !important;
    box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    padding: 5px;
    margin-top: 30px;
    transform: none !important;
    border: none;
    float: right !important;
    right: 20px !important;
    top: 15px !important;
  }
}

@media (max-width: 767px) {
  .productrenewalAlert {
    .policy-details-notifications {
      left: 0 !important;
      width: 100%;
      max-width: 100%;
      transform: none !important;
      box-shadow: 0 0 10px rgba(184, 184, 184, 0.7);
      padding: 0;
      top: 15px !important;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  .productrenewalAlert .policy-details-notifications {
    left: 0 !important;
    width: 100%;
    max-width: 100%;
    transform: none !important;
    box-shadow: 0 0 10px rgba(184, 184, 184, 0.7);
    border: none;
    border-radius: 0;
    padding: 15px 15px 0 15px;
  }
}

.add-policy {
  bs-tooltip-container {
    .tooltip-inner {
      text-align: left !important;
      width: 100%;
      padding: 10px 10px;
      max-width: 300px !important;
      @media (max-width: 767px) {
        max-width: 230px !important;
      }
    }
  }
}

.custom-tooltip-claim-timeline {
  bs-tooltip-container {
    .tooltip-inner {
      max-width: 350px !important;
      width: 350px !important;
      background-color: #004170;
      font-size: 10px;
      padding-bottom: 8px;
      padding-top: 8px;
    }
  }

  .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #004170 !important;
  }
}

/* Policy Dashboard Alert popup end here */

// FNOL changes global
.new-claim {
  .text-danger {
    color: $red !important;
    margin: 0.2rem 0;
  }

  .claims-accordion {
    position: relative;
  }

  accordion-group {
    cursor: pointer;
    position: relative;
    border-bottom: 0.05rem solid $grey;

    &:last-child {
      border: 0;
    }

    .card {
      .panel-collapse {
        background-color: transparent !important;
      }

      border: 0;
      background-color: transparent !important;

      .card-header {
        border: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent !important;
        @media (min-width: 320px) and (max-width: 767px) {
          padding: 0.5em 0;
        }
      }
    }

    .panel-collapse {
      .panel-body {
        background-color: transparent !important;
        padding: 0.2rem 0 !important;
        cursor: default !important;
      }
    }
  }
}

.alert-box {
  text-align: center;

  .btn {
    padding-right: 1.5rem !important;
    margin: auto 0.5rem !important;
    padding-left: 1.5rem !important;
  }
}

.p-dialog {
  font-family: Roboto-Regular, sans-serif !important;
  font-size: 1em !important;

  .p-dialog-header {
    padding: 1em 1.5em;
    background: $white !important;
    text-align: center !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    font-family: Roboto-Regular, sans-serif !important;
    font-size: 1em !important;
  }

  .p-dialog-titlebar-icon {
    &:hover {
      border: none !important;
    }
  }

  .p-dialog-title {
    color: #1b1d1f !important;
    font-weight: 400 !important;
    margin: 0 auto !important;
    font-family: Roboto-Regular, sans-serif !important;
    font-size: 1em !important;
  }

  .p-dialog-header-close {
    position: absolute !important;
    right: -10px !important;
    top: -10px !important;
    border-radius: 100px !important;
    background: $dark-blue !important;
    padding: 4px 2px !important;

    &:hover {
      padding: 5px 3px !important;
    }

    span {
      color: $white !important;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      display: none !important;
    }
  }

  .p-dialog-content {
    padding: 0 1.5rem !important;
    border: none !important;
    text-align: center !important;
    height: auto !important;
    font-family: Roboto-Regular, sans-serif !important;
    font-size: 1em !important;
  }

  .p-dialog-footer {
    text-align: center !important;
    padding: 1rem !important;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    font-family: Roboto-Regular, sans-serif !important;
    font-size: 1em !important;

    button {
      width: 100% !important;
    }
  }
}

// FNOL changes end
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .policyDashboardSwipper {
    .swiper-card {
      max-width: 33.33% !important;
    }
  }
}

.policyDashboardSwipper {
  .swiper-slide {
    height: auto !important;
    // margin: 0 auto;
  }
}

.riskAddress {
  position: relative;
  font-size: 14px;

  .customClass {
    max-width: 100%;
    width: auto;
    box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0;
    padding: 15px;
    border: none;
    @media (max-width: 767px) {
      margin-left: 20px;
    }
  }
}

.cliamClass {
  max-width: 180px;
  margin-left: -48px;
  @media (max-width: 767px) {
    margin-left: -79px;
  }
}

.cliamClassone {
  @media (max-width: 767px) {
    margin-left: 60px;
  }
}

.claims-list-notifications {
  left: -39px !important;
  max-width: 100%;
  z-index: 999;
  width: 450px;
  max-height: 450px;
  overflow-y: scroll;
  box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  padding: 5px;
  border: none;
  top: 50px !important;
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-left: -195px;
  }
  @media (max-width: 767px) {
    transform: none !important;
    margin-left: 0;
    max-width: 100%;
    width: 100%;
    left: 0 !important;
  }
}

.claim-details-notifications {
  margin-left: -209px;
  max-width: 100%;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 999;
  width: 450px;
  box-shadow: 0 8px 8px 8px rgba(0, 0, 0, 0.16);
  border-radius: 0;
  padding: 5px;
  border: none;
  top: 30px !important;
  @media (max-width: 767px) {
    margin-left: 0;
    max-width: 100%;
    transform: none !important;
    width: 100%;
    left: 0 !important;
  }
}

.popup-blocker {
  background: $red;
  color: $white;
  z-index: 10000;
  font-size: 0.75rem;
}

.text-custom {
  border-radius: 0.4rem;
  background-color: #f3f6f8;
  padding: 1rem 0.8rem 0;
  margin-bottom: 10px;
  font-family: "Roboto-Medium", sans-serif;

  p {
    font-size: 0.875rem;
  }
}

figure.item {
  /* To correctly align image, regardless of content height: */
  vertical-align: top;
  display: inline-block;
  /* To horizontally center images and caption */
  text-align: center;
  /* The width of the container also implies margin around the images. */
}

.caption {
  /* Make the caption a block so it occupies its own line. */
  display: block;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.smallRadio .mat-radio-container {
  height: 15px;
  width: 15px;
}

.smallRadio .mat-radio-outer-circle {
  height: 15px;
  width: 15px;
}

.smallRadio .mat-radio-inner-circle {
  height: 15px;
  width: 15px;
}

.smallRadio .mat-radio-button .mat-radio-ripple {
  height: 20px;
  width: 20px;
  left: calc(50% - 10px);
  top: calc(50% - 10px);
}

.mat-expansion-panel {
  box-shadow: none !important;
}

.mat-expansion-panel-body,
.mat-expansion-panel-header {
  padding: 0 !important;
}

.cat-event-expansion-panel {
  .mat-expansion-panel-header-title {
    font-size: 14px;
    color: #004170;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }
}

.claims-policies-list-paginator {
  .mat-mdc-paginator-container {
    justify-content: center;
  }

  @media (max-width: 767px) {
    .mat-mdc-paginator-page-size {
      display: none;
    }
    .mat-mdc-paginator-range-label {
      margin: 0 6px 0 0;
    }
  }
}

// for notification component, to stop closing the notification panel when clicked away from mat menu
.disable-backdrop-click .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  pointer-events: none !important;
}

// Styling for take me to menu in policy landing page
#links {
  .mdc-list-item__primary-text {
    font-size: 12px !important;
  }

  .mat-mdc-menu-item {
    min-height: 30px;
    padding-left: 10px;
  }
}

.take-me-to-button.mat-mdc-button {
  --mat-mdc-button-persistent-ripple-color: transparent;
  --mat-mdc-button-ripple-color: transparent;
}

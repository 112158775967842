$white: #fff;
$astral: #357dab;
$shadow-color1: rgba(184, 184, 184, 0.7);
$dark-blue: #004170;
$light-blue: #4b99d5;
$nevada: #4e595d;
$panel-color: #f3f6f8;
$header-color: #606060;
$input-color: #dbdbdb;
$grey: #ccc;
$dark-grey: #9e9e9e;
$light-grey: #f6f6f6;
$line-grey: #c4c4c4;
$page-bg: #ebc1c1;
$red: red;
$green: #5bba18;
$secondary-text: #007bff;
$black: #000;
$input-text: #080808;
$lighter-grey: #f3f6f8;
$orange: #ff9d00;
$claims-home-icon: #f4f4f4;
/**/

$mq-mobile-portrait: 320px !default;
$mq-mobile-landscape: 480px !default;
$mq-tablet-portrait: 768px !default;
$mq-tablet-landscape: 1024px !default;
$mq-desktop: 1382px !default;
/*Mixins begin here...*/

@mixin flex-centered {
  display: flex;
  justify-content: center;
  align-items: center
}

@mixin contacts-selector {
  .phone-numbers {
    position: relative;

    input:not([type="radio"]) {
      width: 64%;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      z-index: 999;
      height: 50px;
    }

    input[type="radio"] {
      top: 10px;
      position: relative;
    }

    input:read-only {
      background: $white
    }

    select,
    .type-selector {
      width: 32% !important;
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      z-index: 999;
      padding-right: 0;
      color: $input-text !important;
      height: 50px;
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
      border: 1px solid #dbdbdb;
      @media(min-width: 1200px) {
        width: 34% !important
      }
    }
  }
}

/*Common modal styles for Add Policy flow and User Profile flow */

@mixin addPolicy {
  font-family: 'Roboto-Regular', sans-serif !important;
  bottom: auto !important;
  width: 100%;
  top: 15%;
  padding: 1.25rem 1.875rem;
  @media (max-width: 576px) {
    margin: 0 auto !important;
    width: 90vw !important
  }
  @media (min-width: 577px) and (max-width: $mq-tablet-landscape) {
    margin: 0 auto !important;
    width: 75vw !important
  }
  .modal-dialog {
    max-width: 100% !important
  }
  button {
    font-family: 'Roboto-Light', sans-serif !important;
    min-width: 150px;
    width: auto;
    @media(max-width: 767px) {
      min-width: 47%;
    }
  }
  .create-account-header {
    background-position: 30% !important;
    background-size: 25px !important;
    font-size: 1.375rem;
    font-family: 'Roboto-Regular', sans-serif !important;
    @media(max-width: 767px) {
      background-position: 29% !important;
      background-size: 20px !important;
    }
  }
  .modal-content {
    background-color: inherit !important;
    border: none
  }
}

@mixin navigation-bar {
  .navbar {
    box-shadow: 0 3px #f4f4f4;
    background: $white;

    .nav-item {
      &:last-child {
        .nav-link {
          border-right: none;
        }
      }

      .nav-link {
        padding: 0 1.5rem;
        @media (max-width: $mq-tablet-portrait) {
          padding: 0 1rem;
        }

        img {
          display: inline-block;
          width: 25px;
          height: 25px;
          margin-right: 15px
        }

        &.nav-separator {
          border-right: 2px solid $dark-blue;
        }
      }
    }
  }
}

@mixin input-standard {
  height: 60px;
  width: 100%;
  padding-left: 2.8125rem;
  font-size: 1.125rem;
  border-radius: 5px;
  color: $input-text;
}

@mixin login-container {
  border-radius: 10px;
  background-color: rgba($white, 0.95);
  box-shadow: 0px 1px 2px $shadow-color1;
  @media (min-width: 1025px) {
    position: absolute;
    right: 25px;
    padding: 1.25rem 1.875rem;
  }
  @media (max-width: 767px) {
    margin: 15vh auto 0 auto;
    max-width: 90vw;
    padding: 1.25rem 1.5rem;
  }
}

@mixin toggle-passwordState {
  .toggle-icon {
    width: 50px;
    height: 50px;
    background-size: 25px 25px;
    position: absolute;
    top: 0px;
    right: 0;
  }
}

@mixin usermodule-bg {
  background: url("../images/bg/login_bg.png") no-repeat fixed;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
  overflow-y: auto;
}

$icomoon-font-path: "../fonts" !default;
$icon-enroll-path1: "\e946";
$icon-enroll-path2: "\e9d2";
$icon-enroll-path3: "\e9d3";
$icon-FAQs: "\e9d4";
$icon-Notification_new-path1: "\e9d5";
$icon-Notification_new-path2: "\e9d6";
$icon-switch_off_light-path1: "\e9d7";
$icon-switch_off_light-path2: "\e9d8";
$icon-switch_on_light-path1: "\e9d9";
$icon-switch_on_light-path2: "\e9da";
$icon-Thunderstorm: "\e9db";
$icon-other: "\e9d1";
$icon-info_white-path1: "\e913";
$icon-info_white-path2: "\e914";
$icon-info_white-path3: "\e917";
$icon-info_white-path4: "\e919";
$icon-info_white-path5: "\e91a";
$icon-inspection-firm-path1: "\e91b";
$icon-inspection-firm-path2: "\e91c";
$icon-inspection-firm-path3: "\e92f";
$icon-adjuster-path1: "\e930";
$icon-adjuster-path2: "\e943";
$icon-adjuster-path3: "\e9c6";
$icon-adjuster-path4: "\e9c7";
$icon-adjuster-path5: "\e9c8";
$icon-adjuster-path6: "\e9c9";
$icon-emergency-services-path1: "\e9ca";
$icon-emergency-services-path2: "\e9cb";
$icon-emergency-services-path3: "\e9cc";
$icon-emergency-services-path4: "\e9cd";
$icon-emergency-services-path5: "\e9ce";
$icon-emergency-services-path6: "\e9cf";
$icon-emergency-services-path7: "\e9d0";
$icon-Group-36482: "\e910";
$icon-Group-36492-path1: "\e911";
$icon-Group-36492-path2: "\e912";
$icon-Group-3645: "\e906";
$icon-Group-36472: "\e9c1";
$icon-Group-36462-path1: "\e9c2";
$icon-Group-36462-path2: "\e9c3";
$icon-Group-36462-path3: "\e9c4";
$icon-Group-36462-path4: "\e9c5";
$icon-Group-3622-path1: "\e9b2";
$icon-Group-3622-path2: "\e9b3";
$icon-Group-3622-path3: "\e9b4";
$icon-Group-36342-path1: "\e9a9";
$icon-Group-36342-path2: "\e9aa";
$icon-Group-36342-path3: "\e9ab";
$icon-Group-36342-path4: "\e9b7";
$icon-Group-36342-path5: "\e9b8";
$icon-Group-36352-path1: "\e9b9";
$icon-Group-36352-path2: "\e9bd";
$icon-Group-36352-path3: "\e9be";
$icon-Group-36352-path4: "\e9bf";
$icon-Group-36352-path5: "\e9c0";
$icon-Group-36312: "\e9a3";
$icon-Group-3619-path1: "\e9a4";
$icon-Group-3619-path2: "\e9a5";
$icon-Group-3619-path3: "\e9a6";
$icon-Group-3616-path1: "\e9a7";
$icon-Group-3616-path2: "\e9a8";
$icon-Group-3620-path1: "\e9ac";
$icon-Group-3620-path2: "\e9ad";
$icon-Group-3620-path3: "\e9ae";
$icon-Group-3621-path1: "\e9af";
$icon-Group-3621-path2: "\e9b0";
$icon-Group-3621-path3: "\e9b1";
$icon-Group-3623: "\e9b5";
$icon-Group-36242: "\e9b6";
$icon-noun_915590_cc: "\e9ba";
$icon-noun_952915_cc: "\e9bb";
$icon-noun_952921_cc: "\e9bc";
$icon-Video-path1: "\e9a1";
$icon-Video-path2: "\e9a2";
$icon-Information_inactive-state: "\e99a";
$icon-Policy-Documents_inactive-state-path1: "\e99b";
$icon-Policy-Documents_inactive-state-path2: "\e99c";
$icon-Policy-Documents_inactive-state-path3: "\e99d";
$icon-Policy-Documents_inactive-state-path4: "\e99e";
$icon-Policy-Documents_inactive-state-path5: "\e99f";
$icon-Policy-Documents_inactive-state-path6: "\e9a0";
$icon-accordion-open: "\e900";
$icon-accordion_closed: "\e901";
$icon-Agent: "\e902";
$icon-Back: "\e903";
$icon-Billing-n-Payments: "\e904";
$icon-Billing-n-Paymetn_gray: "\e905";
$icon-Change-Edit: "\e907";
$icon-Chat-with-us: "\e908";
$icon-Check-box_unchecked-path1: "\e909";
$icon-Check-box_unchecked-path2: "\e90a";
$icon-check_on_light: "\e90b";
$icon-Claims: "\e90c";
$icon-Close-path1: "\e90d";
$icon-Close-path2: "\e90e";
$icon-Close-path3: "\e90f";
$icon-Doors: "\e915";
$icon-Exit-Logout: "\e916";
$icon-Fax: "\e918";
$icon-Group-3626-path1: "\e91d";
$icon-Group-3626-path2: "\e91e";
$icon-Group-3626-path3: "\e91f";
$icon-Group-3627: "\e920";
$icon-Group-3628-path1: "\e921";
$icon-Group-3628-path2: "\e922";
$icon-Group-3628-path3: "\e923";
$icon-Group-3628-path4: "\e924";
$icon-Group-3629-path1: "\e925";
$icon-Group-3629-path2: "\e926";
$icon-Group-3630-path1: "\e927";
$icon-Group-3630-path2: "\e928";
$icon-Group-3630-path3: "\e929";
$icon-Group-3630-path4: "\e92a";
$icon-Group-3631: "\e92b";
$icon-Group-3632-path1: "\e92c";
$icon-Group-3632-path2: "\e92d";
$icon-Group-3632-path3: "\e92e";
$icon-Group-3634: "\e931";
$icon-Group-3635-path1: "\e932";
$icon-Group-3635-path2: "\e933";
$icon-Group-3635-path3: "\e934";
$icon-Group-3636-path1: "\e935";
$icon-Group-3636-path2: "\e936";
$icon-Group-3637-path1: "\e937";
$icon-Group-3637-path2: "\e938";
$icon-Group-3637-path3: "\e939";
$icon-Group-3638-path1: "\e93a";
$icon-Group-3638-path2: "\e93b";
$icon-Group-3638-path3: "\e93c";
$icon-Group-3638-path4: "\e93d";
$icon-Group-3638-path5: "\e93e";
$icon-Group-3639-path1: "\e93f";
$icon-Group-3639-path2: "\e940";
$icon-Group-3639-path3: "\e941";
$icon-Group-3639-path4: "\e942";
$icon-Group-3641: "\e944";
$icon-Group-3646: "\e945";
$icon-Group-3648: "\e947";
$icon-Group-3649-path1: "\e948";
$icon-Group-3649-path2: "\e949";
$icon-Group-3649-path3: "\e94a";
$icon-Group-3650-path1: "\e94b";
$icon-Group-3650-path2: "\e94c";
$icon-Group-3650-path3: "\e94d";
$icon-Group-3651-path1: "\e94e";
$icon-Group-3651-path2: "\e94f";
$icon-Group-3651-path3: "\e950";
$icon-Group-3652-path1: "\e951";
$icon-Group-3652-path2: "\e952";
$icon-Group-3652-path3: "\e953";
$icon-Group-3655-path1: "\e954";
$icon-Group-3655-path2: "\e955";
$icon-Group-3655-path3: "\e956";
$icon-Group-3655-path4: "\e957";
$icon-Group-3656: "\e958";
$icon-Group-3657-path1: "\e959";
$icon-Group-3657-path2: "\e95a";
$icon-Group-3657-path3: "\e95b";
$icon-Group-3657-path4: "\e95c";
$icon-Group-3658: "\e95d";
$icon-Group-3659: "\e95e";
$icon-Group-3660: "\e95f";
$icon-Group-3661: "\e960";
$icon-Group-3662: "\e961";
$icon-Group-3663: "\e962";
$icon-Group-3664: "\e963";
$icon-Group-3665: "\e964";
$icon-Group-3666: "\e965";
$icon-Group-3667: "\e966";
$icon-Group-3668: "\e967";
$icon-Group-3669-path1: "\e968";
$icon-Group-3669-path2: "\e969";
$icon-Group-3669-path3: "\e96a";
$icon-Group-3669-path4: "\e96b";
$icon-Group-3669-path5: "\e96c";
$icon-Group-3672-path1: "\e96d";
$icon-Group-3672-path2: "\e96e";
$icon-Group-3672-path3: "\e96f";
$icon-Group-3672-path4: "\e970";
$icon-Group-3672-path5: "\e971";
$icon-Group-3672-path6: "\e972";
$icon-Group-3672-path7: "\e973";
$icon-Group-3672-path8: "\e974";
$icon-Group-3672-path9: "\e975";
$icon-Group-3673-path1: "\e976";
$icon-Group-3673-path2: "\e977";
$icon-Group-3673-path3: "\e978";
$icon-Group-3674-path1: "\e979";
$icon-Group-3674-path2: "\e97a";
$icon-Group-3674-path3: "\e97b";
$icon-Group-3674-path4: "\e97c";
$icon-Group-3675: "\e97d";
$icon-Group-3676-path1: "\e97e";
$icon-Group-3676-path2: "\e97f";
$icon-Group-3681-path1: "\e980";
$icon-Group-3681-path2: "\e981";
$icon-Group-3681-path3: "\e982";
$icon-History: "\e983";
$icon-Image: "\e984";
$icon-new: "\e985";
$icon-Notifications-Alerts: "\e986";
$icon-noun_-1: "\e987";
$icon-noun_61554: "\e988";
$icon-noun_76181_cc: "\e989";
$icon-noun_173601_cc: "\e98a";
$icon-noun_216058_cc: "\e98b";
$icon-Password-Secure: "\e98c";
$icon-Path-1361: "\e98d";
$icon-Path-1363: "\e98e";
$icon-Phone-contact-path1: "\e98f";
$icon-Phone-contact-path2: "\e990";
$icon-Preferences: "\e991";
$icon-Profile-Account: "\e992";
$icon-Quick: "\e993";
$icon-radio_on-1: "\e994";
$icon-radio_on-2: "\e995";
$icon-radio_on: "\e996";
$icon-Receipt: "\e997";
$icon-roof: "\e998";
$icon-Wall: "\e999";
/*Alert Notification popup - Policy Dashboard and Policy details*/

@mixin alertNotification {
  #notificationPopup {
    @media (max-width: 767px) {
      .notifications-details {
        padding: 0;
      }
      .notification-hd-title {
        font-size: 14px !important;
      }
      .notifications-claims {
        width: 45px !important;
      }
      .notification-title {
        font-size: 13px !important;
      }
      .notification-text {
        font-size: 12px !important;
      }
    }
    @media (max-width: 576px) {
      .notifications-claims {
        width: 40px !important;
      }
      .notification-title {
        font-size: 12px !important;
      }
      .notification-text {
        font-size: 11px !important;
      }
    }

    .modal-lg-custom {
      padding: 20px !important;
    }

    .notifications-details {
      border-top: solid 0.5px #c4c4c4;
      padding: 0 15px;
    }

    .policy-Notify-Icon {
      font-size: 70px;
    }

    .icon-Claims:before {
      font-size: 70px;
      padding-right: 10px;
    }

    .notification-header {
      display: flex !important;
      justify-content: space-between;
      align-items: center;

      h4 {
        font-family: "Roboto-Medium", sans-serif;
        font-size: 1.17rem;
        color: #515151;
      }

      .notification-hd-title {
        font-size: 16px;
        font-family: "Roboto-Medium", sans-serif;
      }
    }

    .notifications-claims {
      width: 50px;
      height: auto;
      padding-right: 20px;
    }

    .notification-title {
      font-family: "Roboto-Medium", sans-serif;
      font-size: 13px;
      text-align: left;
      color: #515151;
      width: auto;
    }

    .notification-text-bold {
      font-weight: bold;
    }

    .notification-text {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 12px;
      text-align: left;
    }

    .notification-text-policy {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 12px;
      color: #6a9dd4;
      text-align: left;
    }

    .nonotification-text {
      font-family: "Roboto-Regular", sans-serif;
      font-size: 12px;
      text-align: left;
      @media (max-width: 767px) {
        font-size: 0.875rem;
      }
    }
  }
}

/*Alert Notification popup - Policy Dashboard and Policy details*/

@mixin renewalNotification {
  #renewalModal {
    .modal-lg-custom {
      @media (max-width: 767px) {
        margin-top: 20px;
      }

      h4 {
        color: $dark-blue;
        font-family: "Roboto-Regular", sans-serif;
        font-size: 1rem;
        @media (max-width: 767px) {
          font-size: 1.25rem;
        }
      }

      .modal-content {
        width: auto;
        margin: 0 5%;

        .icon-delete {
          cursor: pointer;
          right: -15px;
          top: -15px;
          position: absolute;
          width: 36px;
          height: 36px;
        }
      }

      .modal-body {
        font-size: 0.875rem;
        font-family: "Roboto-Regular", sans-serif;
        @media (max-width: 767px) {
          font-size: 1rem;
        }
      }
    }
  }
}
